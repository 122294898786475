@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;400;500;700;900&display=swap");

* {
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
  margin: 0 10px;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  color: rgb(var(--foreground-rgb));
  background-image: url("./bgjoaoa.jpg");
  font-family: "Inter", sans-serif;
  background-size: cover;
  background-repeat: repeat-x;
  background-color: #000;
  background-position-x: center;
  margin: 0;
  padding: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 100vh;
}

.audio-player {
  background: transparent;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
}

.inner {
  margin: 0 auto;
  padding: 0;
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
}

.trackBox {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* audio info */
.audio-info {
  display: flex;
  gap: 20px;
  position: absolute;
  width: 100%;
  height: 100%;
}
.text {
  color: #fff;
}

.title {
  color: #fff;
  font-weight: 800;
  font-size: 24px;
  margin-bottom: 0;
  padding: 2px;
  line-height: 28px;
}

.artist {
  opacity: 1;
  margin: 0px 0 20px;
  color: #ffffff;
}

/* audio image */
.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.audio-image {
  width: 100%;
  height: 100%;
  background: transparent;
}

.audio-icon {
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background: #fff;
}

.audio-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  vertical-align: middle;
}
.audio-image-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.blackFade {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(2, 2, 2, 0) 0%,
    rgba(2, 2, 2, 0.85) 90%
  );
  pointer-events: none;
  z-index: 5;
}

.audio-info .text {
  position: absolute;
  width: 100%;
  bottom: 200px;
  z-index: 300;
  padding: 120px 20px 20px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

button.makeRequest {
  align-content: center;
  background-color: transparent;
  height: 34px;
  width: 200px;
  border: none;
  border-radius: 50px;
  color: #eaeaea;
  font-size: 12px;
  font-weight: 900;

  padding: 0 12px;
}

button.makeRequest svg {
  fill: #eaeaea;
  margin-right: 5px;
}

.gradient-border svg {
  fill: #eaeaea;
  margin-right: 5px;
}

.gradient-border {
  --border-width: 3px;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 115px;
  height: 40px;
  font-size: 12px;
  text-transform: uppercase;
  color: white;
  background-color: #020202d1;
  border-radius: 50px;
  font-weight: 900;
}
.gradient-border:hover {
  background-color: #02020280;
}

.gradient-border::after {
  position: absolute;
  content: "";
  top: calc(-1 * var(--border-width));
  left: calc(-1 * var(--border-width));
  z-index: -1;
  width: calc(100% + var(--border-width) * 2);
  height: calc(100% + var(--border-width) * 2);

  background: linear-gradient(
    60deg,
    #e5eb1c,
    #1ec766,
    #f57f36,
    #6d81f5,
    #f68fc0,
    #f55958,
    #96c6f5,
    #d54db9
  );

  background-size: 300% 300%;
  background-position: 0 50%;
  border-radius: calc(2 * 50px);
  animation: moveGradient 4s alternate infinite;
}

@keyframes moveGradient {
  50% {
    background-position: 100% 50%;
  }
}

/* controls */
.controls-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  z-index: 900;
  position: absolute;
  bottom: 80px;
  width: 100%;
  padding: 36px 0 59px;
  text-align: center;
}
.controls button,
.volume button {
  border: none;
  background-color: transparent;
  margin: 0 20px;
  cursor: pointer;
}

button svg {
  font-size: 40px;
  fill: white;
}

.controls button:hover svg,
.controls button:hover svg path,
.controls button:active svg,
.controls button:active svg path {
  fill: blue;
}

button:active,
.button:active {
  opacity: 0.5;
}

.volume {
  display: flex;
  align-items: center;
}

.volume button {
  margin: 0;
}

.progress {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  display: none;
}

/* time */
.time {
  color: #333;
  font-size: 13px;
  line-height: 46px;
}

.time.current {
  color: #fff;
}

/* Top bar */
.top__bar {
  line-height: 1.25rem;
  text-align: left;
  width: 100%;
  color: #fff;
  padding: 20px;
  font-size: 25px;
  position: absolute;
  top: 0;
  display: flex;
  z-index: 200;
  flex-direction: column;
  gap: 30px;
  font-size: 14px;
}

.top__bar svg {
  max-width: 100%;
}

.top__bar a {
  color: inherit;
}

.top__bar p {
  margin: 0;
}

.controls {
  text-align: center;
  display: flex;
}

.volume {
  display: none;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 5s linear infinite;
  -moz-animation: rotating 5s linear infinite;
  -ms-animation: rotating 5s linear infinite;
  -o-animation: rotating 5s linear infinite;
  animation: rotating 5s linear infinite;
}

.marquee {
  color: black;
  background: #000aff;
  position: fixed;
  bottom: -2px;
  width: 100%;
  z-index: 900;
  font-weight: 900;
  text-align: center;
  padding-bottom: 2px;
  --duration: 15s;
  --gap: var(--space);
  display: flex;
  overflow: hidden;
  -webkit-user-select: none;
  user-select: none;
  gap: var(--gap);
  font-size: 20px;
  line-height: 18px;
  letter-spacing: -0.02rem;
  text-transform: uppercase;
}

.marquee .listeners,
.marquee .plays,
.marquee .location {
  margin: 0;
  padding-top: 5px;
  text-transform: uppercase;
  padding-bottom: 5px;
  z-index: 10;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}

.marquee__group {
  align-items: center;
  display: flex;
  gap: 2px;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: nowrap;
}

.animating .marquee__group {
  -webkit-animation: scroll 35s linear infinite;
  animation: scroll 35s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .marquee__group {
    animation-play-state: paused;
  }
}

.marquee__group img {
  max-width: clamp(10rem, 1rem + 28vmin, 20rem);
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 1rem;
}
.innerMarquee *,
.marquee__group *:not(div) {
  margin: 0 2px;
}

.marquee__group svg {
  position: relative;
  top: -1px;
}

.innerMarquee span,
.marquee__group span {
  margin: 0 5px 0 0 !important;
  display: block;
  white-space: nowrap;
}

.innerMarquee span:last-child,
.marquee__group span:last-child {
  margin: 0 !important;
}

.innerMarquee {
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-around;
}

.marquee--borders {
  border-block: 3px solid dodgerblue;
  padding-block: 0.75rem;
}

.marquee--reverse .marquee__group {
  animation-direction: reverse;
  animation-delay: calc(15s / -2);
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-100%));
  }
}

.requests {
  position: absolute;
  top: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  overflow: scroll;
  background: black;
  color: white;
  padding-bottom: 100px;
}

.requestsTopBar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 20px;
  align-content: center;
  align-items: center;
}

.close {
  cursor: pointer;
}

.requestsTopBar h4 {
  font-size: 1.5em;
}

.requestedSong {
  background: #e5eb1c;
  display: flex;
  color: black;
  border-radius: 130px;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 25px 25px;
  margin-top: -7px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.requestedSong:nth-child(2n + 0) {
  background: #1ec766;
}
.requestedSong:nth-child(3n + 0) {
  background: #f57f36;
}
.requestedSong:nth-child(4n + 0) {
  background: #6d81f5;
}
.requestedSong:nth-child(5n + 0) {
  background: #f68fc0;
}
.requestedSong:nth-child(6n + 0) {
  background: #f55958;
}
.requestedSong:nth-child(7n + 0) {
  background: #96c6f5;
}
.requestedSong:nth-child(8n + 0) {
  background: #d54db9;
}
.requestedSong:nth-child(9n + 0) {
  background: #e5eb1c;
}

.requestedSong:hover {
  opacity: 0.8;
}

.songInfo {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 5px;
}

.requestedSong__info__song {
  font-size: 1.2em;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: -0.025em;
  line-height: 0.9em;
  text-overflow: ellipsis;
  width: 148px;
  overflow: hidden;
  white-space: nowrap;
}
.requestedSong__info__artist {
  font-size: 0.7em;
}

.votes {
  font-size: 1.8em;
  font-weight: 400;
}

.votesInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.playerPlaying .text {
  bottom: auto;
  /* text-align: center; */
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  text-align: center;
  color: white;
  height: 100%;
  padding: 0;
  font-size: 30px;
}
.playerPlaying .text .title {
  font-size: 70px;
  line-height: 80px;
}

video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: block;
  object-fit: cover;
  border-radius: 1px;
}

#joaoVid {
  z-index: 1;
}
#myVideo {
  z-index: 2;
}

@media only screen and (min-width: 768px) {
  .controls-wrapper {
    bottom: 10px;
    width: 40%;
    left: 50%;
    transform: translateX(-50%);
  }

  .audio-info .text {
    bottom: 40px;
    padding: 120px 50px 20px;
  }

  .top__bar p {
    margin: 0;
    width: 50%;
    font-size: 18px;
    line-height: 30px;
    opacity: 0.7;
    margin-top: 77px;
  }
}

@media only screen and (max-width: 768px) {
  .top__bar {
    margin-top: -40px;
  }
  .top__bar p {
    margin: 0;
    margin-top: -40px;
  }

  .playerPlaying .text {
    font-size: 30px;
  }

  .playerPlaying .text .title {
    font-size: 40px;
    line-height: 50px;
  }
}
